import { FaCheck } from "react-icons/fa";
import { Step } from "../step";

export const Steps = ({
  mqr,
  kyb,
  offer_signed,
  mqr_data,
  offer_status,
  files,
  filesApproved,
}: {
  mqr: boolean;
  kyb: boolean;
  offer_signed: boolean;
  mqr_data: any;
  offer_status?: string;
  files: any;
  filesApproved: number;
}) => {
  const steps = [
    {
      id: 1,
      label: "Fill MQR",
      rank: 1,
      isDone: mqr,
      handle: "/account-setup/company-profile",
      payload: mqr_data,
    },
    {
      id: 2,
      label: "View and sign offer",
      rank: 2,
      isDone: offer_signed,
      handle: "",
      offer_status: offer_status,
      payload: files,
    },
    {
      id: 3,
      label: "KYB upload",
      rank: 3,
      isDone: kyb,
      handle: "/account-setup/kyb-uploads",
      filesApproved: filesApproved,
    },
    {
      id: 4,
      label: "Sign agreement",
      rank: 4,
      isDone: false,
      handle: "/",
    },
  ];
  let currentStep = kyb ? 4 : offer_signed ? 3 : mqr ? 2 : 1;
  return (
    <div className="flex flex-col gap-y-2">
      {steps.map((step) => (
        <Step key={step.id} currentStep={currentStep} step={step} />
      ))}
    </div>
  );
};
